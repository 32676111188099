<div class="containerBanner">
  <!-- Search and Filter Menu -->
  <div class="menu-container">
    <h1>Pending Requests<span>({{ this.totalInventoryRequest}})</span></h1>
    <div class="menu-content d-flex align-items-center justify-content-between">
      <div class="search-menu">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
          class="search-icon">
          <g opacity="0.4">
            <circle cx="11.0795" cy="11.3768" r="7.62168" transform="rotate(-45 11.0795 11.3768)" stroke="#092C4C"
              stroke-width="1.72802" stroke-linecap="round" />
            <path d="M16.6416 16.9393L19.8465 20.1443" stroke="#092C4C" stroke-width="1.72802" stroke-linecap="round" />
          </g>
        </svg>
        <input type="search" placeholder="Search requests.." />
      </div>
      <div class="btn-container d-flex align-items-center justify-content-between">
        <div class="month-type">
          <mat-form-field class="example-form-field" style="
              --mat-form-field-filled-with-label-container-padding-bottom: 0px;
              --mat-form-field-container-height: 0px;
              --mat-form-field-filled-with-label-container-padding-top: 0px;
              --mdc-filled-text-field-container-color: #fff;
              --mat-form-field-container-vertical-padding: 10px;
            ">
            <mat-date-range-input [rangePicker]="rangePicker">
              <input matStartDate placeholder="Start date" />
              <input matEndDate placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Reset</button>
                <button style="
                    --mdc-protected-button-container-color: #fecc28;
                    --mdc-protected-button-label-text-color: #000;
                  " mat-raised-button color="primary" matDateRangePickerApply>
                  Apply
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </div>

        <select id="admin" class="form-control" formControlName="admin" style="margin-left: 15px">
          <option value="admin" selected>All Admins</option>
        </select>

        <select id="open-req" class="form-control" formControlName="open-req">
          <option value="open-req" selected>Open Requests</option>
          <option value="forw-req" selected>Forward Requests</option>
        </select>

        <button class="d-flex align-items-center justify-content-center add-inv" type="button" data-toggle="modal"
          data-target="#inventoryRequestModal" (click)="openInventoryRequest()">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 32 32"
            style="margin-right: 8px">
            <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
          </svg>
          Inventory
        </button>
      </div>
    </div>
  </div>

  <!-- Pending Request Table -->
  <div class="checked-out-inv">
    <table>
      <thead>
        <tr>
          <th scope="col" style="width: 15%;">ASSET NAME</th>
          <th scope="col" style="width: 15%;">REQUESTED BY</th>
          <th scope="col" style="width: 15%;">STATUS</th>
          <th scope="col" style="width: 15%;">REQUESTED ON</th>
          <th scope="col" style="width: 15%;">START DATE</th>
          <th scope="col" style="width: 15%;">FOR PROJECT</th>
          <th scope="col" style="width: 15%;">LOCATIONS</th>
          <th scope="col" style="width: 15%;">URGENCY</th>
          @if (showForwardButton) {
          <th scope="col" style="padding-left: 30px;">ACTIONS</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (inventoryrequest of inventoriesReq; track $index) {
        <tr data-toggle="modal" data-target="#requestDetailModal">
          <td>
            <div class="d-flex align-items-center">
              <div class="inventory-img">
                <img
                  [src]="inventoryrequest.assetImage? inventoryrequest.assetImage : '../../../assets/inventories/computer-system.jpg' "
                  alt="" style="width: 60px;height: 60px;" />
              </div>
              <p>{{inventoryrequest.assetName}}</p>
            </div>
          </td>
          <td>
            <p>{{inventoryrequest.requestedBy}}</p>
          </td>
          <td>
            <p class="status-point">{{inventoryrequest.status}}</p>
          </td>
          <td>
            <p>{{inventoryrequest.requestedOn}}</p>
          </td>
          <td>
            <p>{{inventoryrequest.startDate}}</p>
          </td>
          <td class="project-name">
            <p>{{inventoryrequest.project}}</p>
          </td>
          <td>
            <p>{{inventoryrequest.location}}</p>
          </td>
          <td>
            <p [ngClass]="{
              'text-red': inventoryrequest.urgency === 'High',
              'text-orange': inventoryrequest.urgency === 'Medium',
              'text-blue': inventoryrequest.urgency === 'Low'
            }">{{inventoryrequest.urgency}}</p>
          </td>
          <td style="padding-left: 30px;">
            @if (showForwardButton) {
            <div class="d-flex align-items-center">
              <button class="accept" style="margin-right: 10px;"
                (click)="acceptRequest(inventoryrequest.requestId)">Accept</button>
              <button class="decline" (click)=" declineRequest(inventoryrequest.requestId)">Decline</button>
            </div>
            }
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>

  <!-- Delete Request Table -->
  <div class="checked-out-inv d-none" style="overflow-x: auto; max-height: 610px">
    <table>
      <thead>
        <tr>
          <th scope="col">ASSET NAME</th>
          <th scope="col">REQUESTED BY</th>
          <th scope="col">REQUESTED TO</th>
          <th scope="col">STATUS</th>
          <th scope="col">REQUESTED ON</th>
          <th scope="col">START DATE</th>
          <th scope="col">FOR PROJECT</th>
          <th scope="col">LOCATIONS</th>
          <th scope="col">REQUESTED FOR</th>
          <th scope="col">DELETED ON</th>
          <th scope="col">DELETED BY</th>
          <th scope="col">REASON</th>
        </tr>
      </thead>
      <tbody>
        <tr data-toggle="modal" data-target="#requestDetailModal">
          <td>
            <div class="d-flex align-items-center">
              <div class="inventory-img">
                <img src="../../../../assets/inventories/drone.png" alt="" />
              </div>
              <p>DJI Mini 4 Pro Fly More Combo</p>
            </div>
          </td>
          <td>
            <p>Anoop Kumar</p>
          </td>
          <td>
            <p>Tanvi Singh</p>
          </td>
          <td>
            <p class="deleted-status">Deleted</p>
          </td>
          <td>
            <p>June 15 (15 D ago)</p>
          </td>
          <td>
            <p>July 15 2024</p>
          </td>
          <td class="project-name">
            <p>Jack & Jones</p>
          </td>
          <td>
            <p>Mumbai</p>
          </td>
          <td>
            <p>5 Days</p>
          </td>
          <td>
            <p>Today</p>
          </td>
          <td>
            <p>Tanvi Singh</p>
          </td>
          <td>
            <p class="other-details">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
              repellendus aliquam amet totam voluptates similique distinctio
              saepe alias, modi fugit, doloremque pariatur, ad magni
              consequatur?
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Request Form Modal -->
  <div class="modal fade" id="inventoryRequestModal" tabindex="-1" role="dialog"
    aria-labelledby="addInventoryModalTitle" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered main-width-content" role="document">

      <div class="modal-content">
        <div class="modal-header addInventory-head">

          <h5 class="modal-title" id="addInventoryModalTitle">
            Request Inventory
          </h5>

          <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
            <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
                stroke-linecap="round" />
              <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
                stroke-linecap="round" />
            </svg>
          </button>
        </div>

        <div class="modal-body ">
          @if (!formSubmitted) {
          <div class="new-inventory-container">
            <form [formGroup]="inventoryRequestForm" (ngSubmit)="onSubmitForm()">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="assetCategory" class="form-label asterisk-lbl">Select Asset</label>
                  <mat-form-field style="width: 378px!important;">
                    <div class="d-flex justify-content-between align-items-center">

                      <input type="text" placeholder="search Inventory" aria-label="Number" matInput
                        [formControl]="selectedInventoryControl" [matAutocomplete]="auto"
                        style="border: none;height: auto;border-radius:0px;padding-left:0px!important;"
                        [(ngModel)]="searchQuery" (keyup.enter)="onSearchInventory()">
                      @if(searchQuery){
                      <img class="search-close" style="cursor: pointer" src="../../../assets/closeIcon.png" alt=""
                        (click)="clearSearch()" />
                      }
                      @if(!searchQuery){
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15a1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16" />
                      </svg>
                      }
                    </div>
                    <mat-autocomplete #auto="matAutocomplete" formControlName="selectedInventory" placeholder="select"
                      (optionSelected)="onInventoryChange($event)" [displayWith]="displayFn">

                      @for (inventories of inventory; track $index) {
                      <mat-option [value]="inventories"><img
                          [src]="inventories.images[0]? inventories.images[0] : '../../../assets/inventories/computer-system.jpg' "
                          alt="" style="width: 30px;height:30px;" /> {{inventories.viewValue}}
                      </mat-option>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="form-group col-md-4">
                  <label for="startDate" class="form-label asterisk-lbl">Start Date</label>
                  <input type="date" id="storage" class="form-control" id="startDate" formControlName="startDate" />
                </div>
                <div class="form-group col-md-4">
                  <label for="projectName" class="form-label asterisk-lbl">Project Name</label>
                  <input type="text" class="form-control" id="projectName" formControlName="projectName">
                </div>

              </div>
              <div class="form-row">
                <div class="form-group col-md-4 request-for" formGroupName="requestFor">
                  <label for="">Request For</label>
                  <div class="d-flex">
                    <div class="col-md-6 styled-select" style="padding-left: 0;">
                      <mat-form-field style="max-width: 175px;" placeholder="select">
                        <mat-select formControlName="number" placeholder="select">
                          @for(number of numbers; track $index){
                          <mat-option [value]="number" style="max-width: 175px;">{{number}}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field style="max-width: 175px;">

                        <mat-select style="max-width: 175px;" formControlName="cycle" placeholder="select">
                          @for (cycle of cycles; track cycle) {
                          <mat-option [value]="cycle.value">{{cycle.viewValue}}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>


                <div class="form-group col-md-4">
                  <label for="projectLocation" class="form-label asterisk-lbl">Project Location(s)</label>
                  <input type="text" id="projectLocation" class="form-control" formControlName="projectLocation" />
                </div>

                <div class="form-group col-md-4">
                  <label for="urgency" class="form-label asterisk-lbl">Urgency</label>

                  <mat-form-field style="width: 378px!important;">
                    <mat-select formControlName="urgency" placeholder="select">
                      @for (urgency of urgencies; track urgency) {
                      <mat-option [value]="urgency.value">{{urgency.viewValue}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row">

                <div class="form-group col-md-4">
                  <label for="approverName" class="form-label asterisk-lbl">Select
                    Approver</label>
                  <mat-form-field style="width: 378px!important;">
                    <mat-select formControlName="approverName" placeholder="select">
                      @for (user of users; track user) {
                      <mat-option [value]="user.value">{{user.viewValue}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group col-md-4">
                  <label for="requestType">Request Type</label>
                  <mat-form-field style="width: 378px!important;">
                    <mat-select formControlName="requestType" placeholder="select">
                      @for (type of types; track type) {
                      <mat-option [value]="type.value">{{type.viewValue}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-warning" style="margin-top: 40px">
                  Submit
                </button>
              </div>
            </form>
          </div>
          }

          @if (formSubmitted) {
          <div class="text-center" style="padding: 30px 0;">
            <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
                stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61" stroke-width="5.33852"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h3 style="padding: 15px 0;font-weight: 600;">Request Submitted!</h3>
            <p style="font-weight: 600;padding-bottom: 30px;">Your request has been submitted, you’ll be
              notified when it gets<br>
              approved and for further steps
            </p>
            <div class="d-flex justify-content-center align-items-center">
              <button class="btn btn-warning close" style="width: 210px; opacity: 1;
                  font-size: 16px;
                  height: 48px;" data-dismiss="modal" aria-label="Close">Okay</button>
            </div>
          </div>
          }
        </div>
      </div>

    </div>
  </div>

</div>