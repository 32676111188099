import { Component } from "@angular/core";
import { HeaderComponent } from "../header/header.component";
import { RouterOutlet, Router } from "@angular/router";
import { InventoryDashboardComponent } from "./inventory-dashboard/inventory-dashboard.component";
import { WorkstationComponent } from "./workstation/workstation.component";
import { NgClass } from "@angular/common";
@Component({
  selector: "app-inventory",
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    InventoryDashboardComponent,
    WorkstationComponent,
    NgClass
  ],
  templateUrl: "./inventory.component.html",
  styleUrl: "./inventory.component.css",
})
export class InventoryComponent {


  // isMenuExpanded = false;
  // activeMenu: string = 'dashboard';

  // onMouseEnter() {
  //   this.isMenuExpanded = true;
  //   if (this.isMenuExpanded && this.activeMenu.includes('service-request')) {
  //     this.activeMenu = 'service-request';
  //   }
  //   console.log(this.activeMenu, "active Menu")
  // }

  // onMouseLeave() {
  //   this.isMenuExpanded = false;
  //   if (!this.isMenuExpanded && this.activeMenu.includes('request')) {
  //     this.activeMenu = 'request';
  //   }
  // }


  // constructor(private router: Router) { }

  // navigateTo(menu: string, route: string) {
  //   this.activeMenu = menu;
  //   this.router.navigate([route]);
  // }


  isMenuExpanded = false;
  activeMenu: string = 'dashboard';
  activeSubMenu: string = '';

  onMouseEnter() {
    this.isMenuExpanded = true;
    if (this.activeMenu === 'request' && this.activeSubMenu) {
      this.activeMenu = this.activeSubMenu;
    }
    console.log(this.activeMenu, "active Menu");
  }

  onMouseLeave() {
    this.isMenuExpanded = false;
    if (this.activeMenu.includes('request')) {
      this.activeSubMenu = this.activeMenu;
      this.activeMenu = 'request';
    }
  }

  constructor(private router: Router) { }

  navigateTo(menu: string, route: string) {
    this.activeMenu = menu;
    if (menu.includes('request')) {
      this.activeSubMenu = menu;
    }
    this.router.navigate([route]);
  }













  // selectInventory(inventory: string) {
  //   this.router.navigate(["/inventory/inventories"]);
  // }
  // selectWorkstation(workstation: string) {
  //   this.router.navigate(["/inventory/workstation"]);
  // }
  // selectServiceRequest(service: string) {
  //   this.router.navigate(["/inventory/service"]);
  // }
  // selectInventoryRequest(inventory: string) {
  //   this.router.navigate(["/inventory/inventory-request"]);
  // }
  // selectNewInventory(newInventory: string) {
  //   this.router.navigate(["/inventory/new-inventory"]);
  // }

}
